import React, { useEffect, useContext, useState } from 'react';
import { useRouter } from 'next/router';

// Core Packages
import { GET_APP_INFORMATION } from '@/graphql/misc';
import { AuthUserContext } from '@/contexts/authUser';
import { GqlContext } from '@/contexts/gql';

// Login Options
import MobileLogin from '@/containers/Login/Mobile';
import OAuthLogin from '@/containers/Login/Oauth';
import {
  clearRecentSearches,
  clearSessionStorage,
  getCookie,
  makeId,
  removeCookies,
} from '@/utils/helper';
import { RECENT_SEARCH_NAMES } from '../../constants';
import { configureApolloClientSSR } from '../../../apollo-client';

export default function Login(props) {
  const { authUser } = useContext(AuthUserContext);
  const { site } = useContext(GqlContext);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [startChecking, setStartChecking] = useState(false);
  const [checkForRedirect, setCheckForRedirect] = useState(() => {});
  const [triggerRedirectCheck, setTriggerRedirectCheck] = useState('');
  const [redirectTo, setRedirectTo] = useState('/');
  const isLoggedIn = !!authUser?.customerId;

  const router = useRouter();
  const { isOauthEnabled, appInfo } = props;
  const useOauth = isOauthEnabled && !site?.isDev;

  useEffect(() => {
    if (isLoggedIn && !shouldRedirect) {
      setShouldRedirect(true);
    }
  }, [authUser]);

  useEffect(() => {
    setCheckForRedirect(
      setInterval(() => {
        setTriggerRedirectCheck(makeId(10));
      }, 1500),
    );
    return () => {
      setCheckForRedirect(() => {});
      setTriggerRedirectCheck('');
    };
  }, [startChecking]);

  useEffect(() => {
    const redirectCookie = getCookie('redirect_to_home');
    if (redirectCookie) {
      clearInterval(checkForRedirect);
      setRedirectTo(
        redirectCookie === 'redirect_to_register' ? '/register' : '/',
      );
      removeCookies([{ name: 'redirect_to_home', path: '/' }]);
      setShouldRedirect(true);
    }
  }, [triggerRedirectCheck]);

  useEffect(() => {
    if (shouldRedirect) {
      router.push(redirectTo);
    }
  }, [shouldRedirect]);

  useEffect(() => {
    if (window.location.pathname.includes('/login') && useOauth) {
      setStartChecking(true);
    }
    // clearCacheData();
    clearRecentSearches(RECENT_SEARCH_NAMES.locations);
    clearRecentSearches(RECENT_SEARCH_NAMES.searches);
    clearRecentSearches(RECENT_SEARCH_NAMES.salesIq);
    clearRecentSearches(RECENT_SEARCH_NAMES.salesIqData);
    clearSessionStorage(RECENT_SEARCH_NAMES.salesIqData);
    removeCookies([
      { name: 'sales1.alerzo-_zldp', path: '/' },
      { name: 'sales1.alerzo-_zldt', path: '/' },
      { name: 'lat', path: '/' },
      { name: 'long', path: '/' },
      { name: 'activeVertical', path: '/' },
    ]);
  }, []);

  return useOauth ? (
    <OAuthLogin {...props} appInfo={appInfo} />
  ) : (
    <MobileLogin {...props} />
  );
}

export async function getServerSideProps(context) {
  const client = configureApolloClientSSR(context);

  const { data } = await client.query({
    query: GET_APP_INFORMATION,
  });

  return {
    props: {
      appInfo: data?.getAppInformation || {},
      isOauthEnabled: !!data?.getAppInformation?.isOauthEnabled,
    },
  };
}
