import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import PageWrapper from '@/components/Layout/PageWrapper';
import Header from '@/components/Header';
import SEOTags from '@/components/SEO';
import { makeId, setCookie, constructOAuthRedirect } from '@/utils/helper';
// import { generateOauthBase } from '@/utils/oauth';
import { GqlContext } from '@/contexts/gql';

import styles from './style.module.css';

const Oauth = () => {
  const iframeRef = useRef(null);
  const { t: translate } = useTranslation();
  const [urlInfo, setUrlInfo] = useState({});
  const { uri } = urlInfo;
  const { site, appInfo } = useContext(GqlContext);

  // Prepare required variables for Oauth to initiate login
  useEffect(() => {
    if (!appInfo?.ouathUrlNew) return;
    const redirectUrl = `${window.location.origin}/oauth`
      .replaceAll(':', '%3A')
      .replaceAll('/', '%2F');

    const oAuthBase = constructOAuthRedirect(appInfo.ouathUrlNew, redirectUrl);
    const newNonce = makeId(10);
    const newState = makeId(10);
    setCookie('nonce', newNonce);
    const newUri = `${oAuthBase}&state=${newState}&nonce=${newNonce}`;

    console.log('uri to load for Oauth is ', newUri);
    // setCookie
    setUrlInfo({ nonce: newNonce, state: newState, uri: newUri });
  }, [appInfo]);

  return (
    <PageWrapper childrenOnly>
      <SEOTags
        title={site?.isSK ? 'SK Express Login' : 'Alerzoshop Login'}
        description={
          site?.isSK
            ? 'Login to your SK Express App'
            : 'Login to your Alerzoshop App'
        }
        keywords={site?.isSK ? 'SK Express, Login' : 'Alerzoshop, Login'}
        siteName={site?.isSK ? 'SK Express Login' : 'Alerzoshop Login'}
      />
      <Header
        title={translate('Login')}
        showBackButton
        className={styles.smallScreen}
      />
      <iframe
        title="Alerzoshop OAuth Login"
        ref={iframeRef}
        src={uri}
        width="100"
        height="800"
        type="text/html"
        className={styles.window}
      />
    </PageWrapper>
  );
};

export default Oauth;
