const Logo = ({ className }) => (
  <svg className={className} width="114" height="42" viewBox="0 0 114 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M59.312 0.000488281H61.4287C61.5732 0.00222889 61.7111 0.0608419 61.8126 0.163615C61.9142 0.266388 61.9711 0.405029 61.9711 0.549499V17.3409C61.9715 17.4126 61.9578 17.4836 61.9308 17.55C61.9038 17.6164 61.864 17.6768 61.8136 17.7278C61.7632 17.7788 61.7033 17.8193 61.6373 17.8471C61.5712 17.875 61.5004 17.8895 61.4287 17.89H59.312C59.2403 17.8895 59.1695 17.875 59.1034 17.8471C59.0374 17.8193 58.9774 17.7788 58.9271 17.7278C58.8767 17.6768 58.8369 17.6164 58.8099 17.55C58.7828 17.4836 58.7691 17.4126 58.7696 17.3409V0.55281C58.7687 0.480869 58.7821 0.409467 58.8089 0.342709C58.8357 0.275951 58.8755 0.21517 58.9259 0.163837C58.9763 0.112504 59.0364 0.0716387 59.1026 0.0436066C59.1689 0.0155744 59.24 0.00091902 59.312 0.000488281Z" fill="#1A51A3" />
    <path d="M64.3989 11.3843C64.3989 7.70327 66.9688 4.87549 70.8218 4.87549C74.8469 4.87549 77.1521 7.67682 77.1521 11.2124C77.1521 11.4274 77.1521 11.6324 77.1521 11.8408C77.1453 11.9803 77.0853 12.1119 76.9844 12.2085C76.8835 12.3051 76.7494 12.3593 76.6097 12.36H67.5641C67.7824 13.9442 68.887 15.1614 71.1261 15.1614C72.4887 15.1614 73.385 14.6156 73.9109 13.8583C73.9758 13.7646 74.0685 13.6937 74.176 13.6557C74.2834 13.6177 74.4001 13.6145 74.5095 13.6466L76.3583 14.1923C76.4387 14.2167 76.5124 14.2593 76.5738 14.3167C76.6351 14.3741 76.6825 14.4448 76.7122 14.5234C76.7419 14.602 76.7532 14.6864 76.7452 14.7701C76.7371 14.8537 76.71 14.9344 76.6659 15.0059C75.7663 16.4446 73.8944 17.8932 71.0798 17.8932C67.1374 17.8932 64.3989 15.2837 64.3989 11.3843ZM74.0233 10.3359C73.8778 8.82447 72.7004 7.60405 70.7986 7.60405C70.0147 7.57476 69.2479 7.83826 68.6476 8.34324C68.0472 8.84822 67.6563 9.55855 67.5508 10.3359H74.0233Z" fill="#1A51A3" />
    <path d="M86.8259 5.42435V7.3393C86.8257 7.41415 86.81 7.48815 86.7799 7.55669C86.7498 7.62524 86.706 7.68687 86.6511 7.73774C86.5962 7.78862 86.5314 7.82763 86.4608 7.8524C86.3901 7.87717 86.3152 7.88716 86.2405 7.88171C83.9948 7.73288 82.7777 9.0062 82.7777 12.0688V17.2944C82.7777 17.4386 82.7207 17.5769 82.619 17.6791C82.5174 17.7814 82.3795 17.8393 82.2353 17.8401H80.1186C80.0471 17.8401 79.9763 17.826 79.9103 17.7985C79.8442 17.7711 79.7843 17.7308 79.7339 17.6801C79.6835 17.6294 79.6436 17.5692 79.6165 17.503C79.5895 17.4368 79.5757 17.3659 79.5762 17.2944V5.63932C79.5757 5.56781 79.5895 5.49692 79.6165 5.43073C79.6436 5.36454 79.6835 5.30436 79.7339 5.25364C79.7843 5.20292 79.8442 5.16267 79.9103 5.1352C79.9763 5.10774 80.0471 5.09362 80.1186 5.09362H82.2353C82.3795 5.09449 82.5174 5.15236 82.619 5.25461C82.7207 5.35685 82.7777 5.49516 82.7777 5.63932V6.92257C83.0919 6.26631 83.5968 5.72013 84.2264 5.35546C84.856 4.99079 85.581 4.82461 86.3066 4.87863C86.447 4.88463 86.5796 4.94486 86.6764 5.04665C86.7733 5.14844 86.8269 5.28385 86.8259 5.42435Z" fill="#1A51A3" />
    <path d="M99.718 15.6376V17.3177C99.7184 17.3934 99.7039 17.4685 99.6751 17.5385C99.6463 17.6085 99.6039 17.6721 99.5504 17.7257C99.4969 17.7792 99.4333 17.8216 99.3633 17.8503C99.2932 17.8791 99.2182 17.8937 99.1425 17.8932H88.6648C88.5122 17.8932 88.3658 17.8326 88.2579 17.7247C88.15 17.6168 88.0894 17.4704 88.0894 17.3177V15.9452C88.0891 15.808 88.1384 15.6752 88.2283 15.5715L94.9852 7.68012H88.8368C88.6842 7.68012 88.5378 7.61951 88.4299 7.51159C88.322 7.40366 88.2613 7.25727 88.2613 7.10465V5.45097C88.2613 5.29835 88.322 5.15198 88.4299 5.04405C88.5378 4.93613 88.6842 4.8755 88.8368 4.8755H98.9275C99.0032 4.87506 99.0783 4.88965 99.1483 4.91841C99.2183 4.94718 99.2819 4.98956 99.3354 5.04309C99.389 5.09661 99.4313 5.16022 99.4601 5.23023C99.4889 5.30025 99.5035 5.37528 99.503 5.45097V6.82353C99.5034 6.96021 99.4553 7.09261 99.3674 7.19726L92.6337 15.0621H99.159C99.3091 15.0656 99.4517 15.1279 99.5563 15.2355C99.6608 15.3432 99.7189 15.4876 99.718 15.6376Z" fill="#1A51A3" />
    <path d="M45.5567 33.0739L47.4187 32.5249C47.5396 32.4893 47.6691 32.4969 47.7849 32.5464C47.9008 32.5959 47.9958 32.6841 48.0537 32.796C48.573 33.7585 49.6445 34.4497 51.0766 34.4497C52.3103 34.4497 53.1107 33.8643 53.1107 33.183C53.1107 32.5745 52.6509 32.2338 51.7547 31.9626L48.6259 31.0366C46.8796 30.5272 45.5964 29.5019 45.5964 27.7457C45.5964 25.6753 47.8255 24.0415 50.5177 24.0415C52.8527 24.0415 54.6949 25.0337 55.6275 26.5385C55.671 26.6099 55.6975 26.6903 55.705 26.7735C55.7125 26.8568 55.7007 26.9406 55.6707 27.0185C55.6407 27.0965 55.5931 27.1666 55.5317 27.2233C55.4704 27.2799 55.3968 27.3218 55.3167 27.3455L53.4943 27.878C53.3881 27.9085 53.2752 27.9058 53.1706 27.8704C53.066 27.8349 52.9747 27.7683 52.9089 27.6796C52.6228 27.3158 52.2553 27.0241 51.836 26.8283C51.4167 26.6324 50.9572 26.5377 50.4945 26.5518C49.5023 26.5518 48.7483 27.0876 48.7483 27.673C48.7483 28.1625 49.079 28.4535 49.7669 28.6486L52.8196 29.5251C54.6651 30.0642 56.2626 30.8183 56.2626 33.0375C56.2626 35.3526 54.083 37.0592 50.9708 37.0592C48.2456 37.0592 46.0924 35.604 45.2425 33.8577C45.2041 33.7868 45.1818 33.7084 45.1772 33.6279C45.1725 33.5474 45.1857 33.4669 45.2157 33.3921C45.2457 33.3173 45.2918 33.25 45.3507 33.195C45.4096 33.14 45.48 33.0986 45.5567 33.0739Z" fill="#00954B" />
    <path d="M70.6893 29.8593V36.4939C70.6897 36.5652 70.676 36.6359 70.6489 36.7019C70.6218 36.7679 70.5818 36.8279 70.5314 36.8784C70.4809 36.9288 70.4209 36.9687 70.3549 36.9958C70.2889 37.023 70.2182 37.0367 70.1469 37.0362H68.0335C67.9621 37.0367 67.8914 37.023 67.8254 36.9958C67.7594 36.9687 67.6994 36.9288 67.6489 36.8784C67.5985 36.8279 67.5586 36.7679 67.5315 36.7019C67.5044 36.6359 67.4906 36.5652 67.491 36.4939V30.4645C67.491 28.5992 66.6411 27.4119 64.8717 27.4119C63.1022 27.4119 61.9149 28.8175 61.9149 30.9739V36.4939C61.9166 36.6366 61.8621 36.7743 61.763 36.877C61.664 36.9798 61.5284 37.0394 61.3857 37.0429H59.269C59.1976 37.0433 59.1269 37.0296 59.0609 37.0025C58.9949 36.9754 58.9349 36.9354 58.8845 36.885C58.834 36.8345 58.7941 36.7746 58.767 36.7086C58.7399 36.6425 58.7261 36.5718 58.7266 36.5005V19.7421C58.7266 19.5983 58.7837 19.4603 58.8854 19.3586C58.9872 19.2569 59.1251 19.1997 59.269 19.1997H61.3857C61.5293 19.2006 61.6667 19.258 61.7683 19.3595C61.8698 19.4611 61.9272 19.5985 61.9281 19.7421V26.3204C62.3927 25.7184 62.9919 25.2337 63.6777 24.9051C64.3635 24.5766 65.1168 24.4134 65.8771 24.4286C69.0653 24.4286 70.6893 26.585 70.6893 29.8593Z" fill="#00954B" />
    <path d="M79.5431 37.0426C78.2557 37.0426 76.9973 36.6609 75.9269 35.9457C74.8566 35.2305 74.0223 34.2139 73.5296 33.0246C73.037 31.8353 72.9081 30.5266 73.1593 29.264C73.4104 28.0014 74.0303 26.8416 74.9406 25.9313C75.8509 25.021 77.0107 24.4011 78.2732 24.15C79.5358 23.8988 80.8445 24.0277 82.0339 24.5204C83.2232 25.013 84.2398 25.8473 84.955 26.9176C85.6702 27.988 86.0519 29.2464 86.0519 30.5338C86.0501 32.2595 85.3639 33.914 84.1436 35.1343C82.9233 36.3546 81.2688 37.0409 79.5431 37.0426ZM79.5431 27.1206C78.8693 27.1206 78.2107 27.3204 77.6505 27.6947C77.0902 28.069 76.6536 28.601 76.3958 29.2235C76.138 29.846 76.0705 30.5309 76.2019 31.1918C76.3334 31.8526 76.6578 32.4595 77.1342 32.936C77.6107 33.4124 78.2177 33.7368 78.8785 33.8683C79.5393 33.9997 80.2242 33.9323 80.8467 33.6744C81.4692 33.4166 82.0012 32.98 82.3755 32.4198C82.7498 31.8595 82.9496 31.2009 82.9496 30.5272C82.9487 29.6239 82.5896 28.758 81.9509 28.1193C81.3122 27.4806 80.4463 27.1215 79.5431 27.1206Z" fill="#00954B" />
    <path d="M107.236 17.8932C105.948 17.8926 104.69 17.5103 103.62 16.7947C102.55 16.0791 101.717 15.0623 101.224 13.8729C100.732 12.6835 100.604 11.3749 100.855 10.1125C101.107 8.85014 101.727 7.69067 102.638 6.78072C103.548 5.87077 104.708 5.25119 105.97 5.00032C107.233 4.74944 108.541 4.87853 109.73 5.37126C110.92 5.864 111.936 6.69826 112.651 7.76857C113.366 8.83887 113.748 10.0972 113.748 11.3843C113.746 13.1106 113.059 14.7657 111.838 15.9861C110.617 17.2064 108.962 17.8923 107.236 17.8932ZM107.236 7.97116C106.562 7.97116 105.903 8.17096 105.343 8.54528C104.783 8.9196 104.346 9.45163 104.088 10.0741C103.83 10.6966 103.763 11.3815 103.894 12.0423C104.026 12.7031 104.35 13.3101 104.827 13.7866C105.303 14.263 105.91 14.5874 106.571 14.7188C107.232 14.8503 107.917 14.7828 108.539 14.525C109.162 14.2672 109.694 13.8305 110.068 13.2703C110.442 12.7101 110.642 12.0515 110.642 11.3777C110.64 10.4757 110.28 9.6114 109.641 8.97416C109.003 8.33693 108.138 7.97865 107.236 7.97778V7.97116Z" fill="#1A51A3" />
    <path d="M55.7234 4.87549H53.6993C53.6285 4.87549 53.5584 4.88944 53.493 4.91653C53.4276 4.94362 53.3682 4.98333 53.3181 5.03339C53.2681 5.08345 53.2283 5.14288 53.2012 5.20828C53.1741 5.27369 53.1602 5.34379 53.1602 5.41459V5.84123C52.174 5.23423 51.0437 4.90136 49.8859 4.87694C48.728 4.85252 47.5847 5.13745 46.5737 5.70233C45.5628 6.2672 44.7208 7.09157 44.1348 8.09038C43.5487 9.0892 43.2397 10.2263 43.2397 11.3843C43.2397 12.5424 43.5487 13.6795 44.1348 14.6783C44.7208 15.6772 45.5628 16.5015 46.5737 17.0664C47.5847 17.6313 48.728 17.9162 49.8859 17.8918C51.0437 17.8674 52.174 17.5345 53.1602 16.9275V17.3541C53.1602 17.4249 53.1741 17.495 53.2012 17.5604C53.2283 17.6258 53.2681 17.6853 53.3181 17.7353C53.3682 17.7854 53.4276 17.8251 53.493 17.8522C53.5584 17.8793 53.6285 17.8932 53.6993 17.8932H55.7234C55.8664 17.8932 56.0035 17.8364 56.1046 17.7353C56.2057 17.6342 56.2625 17.4971 56.2625 17.3541V5.41459C56.2625 5.27161 56.2057 5.13449 56.1046 5.03339C56.0035 4.93229 55.8664 4.87549 55.7234 4.87549ZM49.7536 14.7975C49.0799 14.7975 48.4213 14.5978 47.8611 14.2234C47.3009 13.8491 46.8642 13.3171 46.6064 12.6946C46.3486 12.0721 46.2811 11.3872 46.4125 10.7264C46.544 10.0656 46.8684 9.45858 47.3448 8.98216C47.8212 8.50574 48.4282 8.18129 49.0891 8.04985C49.7499 7.91841 50.4348 7.98586 51.0573 8.2437C51.6797 8.50153 52.2118 8.93818 52.5861 9.49839C52.9604 10.0586 53.1602 10.7172 53.1602 11.391C53.1576 12.293 52.7977 13.1573 52.1592 13.7945C51.5207 14.4318 50.6557 14.79 49.7536 14.7909V14.7975Z" fill="#1A51A3" />
    <path d="M88.5356 24.5574V41.3785C88.5356 41.5215 88.5924 41.6586 88.6935 41.7597C88.7946 41.8608 88.9318 41.9176 89.0747 41.9176H91.1022C91.2451 41.9176 91.3823 41.8608 91.4834 41.7597C91.5845 41.6586 91.6413 41.5215 91.6413 41.3785V36.0868C92.629 36.6948 93.7611 37.0282 94.9207 37.0527C96.0803 37.0772 97.2254 36.7919 98.2379 36.2262C99.2505 35.6605 100.094 34.8348 100.681 33.8345C101.268 32.8341 101.577 31.6953 101.577 30.5354C101.577 29.3755 101.268 28.2367 100.681 27.2363C100.094 26.236 99.2505 25.4103 98.2379 24.8446C97.2254 24.2789 96.0803 23.9936 94.9207 24.0181C93.7611 24.0426 92.629 24.376 91.6413 24.984V24.5574C91.6413 24.4144 91.5845 24.2773 91.4834 24.1762C91.3823 24.0751 91.2451 24.0183 91.1022 24.0183H89.0747C88.9318 24.0183 88.7946 24.0751 88.6935 24.1762C88.5924 24.2773 88.5356 24.4144 88.5356 24.5574ZM91.6413 30.5371C91.6406 29.8618 91.8402 29.2016 92.2149 28.6399C92.5895 28.0782 93.1224 27.6402 93.746 27.3813C94.3696 27.1225 95.056 27.0544 95.7183 27.1857C96.3806 27.3171 96.9891 27.6419 97.4667 28.1191C97.9444 28.5963 98.2699 29.2045 98.4018 29.8666C98.5338 30.5288 98.4664 31.2153 98.2081 31.8391C97.9499 32.463 97.5124 32.9963 96.9511 33.3715C96.3897 33.7467 95.7297 33.9469 95.0545 33.9469C94.1501 33.9461 93.2829 33.5866 92.6431 32.9474C92.0033 32.3082 91.643 31.4414 91.6413 30.5371Z" fill="#00954B" />
    <path d="M32.28 16.1231C32.2794 18.8777 31.5726 21.5861 30.2269 23.9896C28.8812 26.3932 26.9418 28.4115 24.5938 29.8519V2.40088C26.9404 3.84112 28.8789 5.85853 30.2244 8.2607C31.57 10.6629 32.2777 13.3697 32.28 16.1231Z" fill="#00954B" />
    <path d="M32.2799 16.1235V30.9074C32.2799 31.2583 32.1405 31.5948 31.8925 31.8429C31.6444 32.091 31.3079 32.2303 30.957 32.2303H25.9431C25.5922 32.2303 25.2557 32.091 25.0076 31.8429C24.7595 31.5948 24.6201 31.2583 24.6201 30.9074V29.8523C26.9632 28.4088 28.8975 26.389 30.2384 23.9857C31.5793 21.5824 32.2821 18.8756 32.2799 16.1235Z" fill="#FFB207" />
    <path d="M35.7591 8.37334e-09H31.0825H31.0164H30.937H25.923C25.5722 8.37334e-09 25.2357 0.13938 24.9876 0.387479C24.7395 0.635578 24.6001 0.972074 24.6001 1.32294V2.3813C26.9472 3.82183 28.886 5.83975 30.2316 8.24256C31.5772 10.6454 32.2846 13.3529 32.2864 16.1068V9.02575C32.2864 8.67488 32.4257 8.33839 32.6738 8.09029C32.9219 7.84219 33.2584 7.70281 33.6093 7.70281H35.7591C36.11 7.70281 36.4464 7.56343 36.6945 7.31533C36.9426 7.06723 37.082 6.73074 37.082 6.37987V1.34278C37.0847 1.16739 37.0524 0.993235 36.9871 0.830431C36.9218 0.667627 36.8247 0.519419 36.7016 0.394464C36.5785 0.269508 36.4318 0.170289 36.27 0.102567C36.1082 0.034846 35.9345 -1.97268e-05 35.7591 8.37334e-09Z" fill="#FFB207" />
    <path d="M28.338 42.0003C30.4076 42.0003 32.0853 40.3226 32.0853 38.2531C32.0853 36.1836 30.4076 34.5059 28.338 34.5059C26.2685 34.5059 24.5908 36.1836 24.5908 38.2531C24.5908 40.3226 26.2685 42.0003 28.338 42.0003Z" fill="#1A51A3" />
    <path d="M3.78043 42.0003C5.84997 42.0003 7.52766 40.3226 7.52766 38.2531C7.52766 36.1836 5.84997 34.5059 3.78043 34.5059C1.71089 34.5059 0.0332031 36.1836 0.0332031 38.2531C0.0332031 40.3226 1.71089 42.0003 3.78043 42.0003Z" fill="#1A51A3" />
    <path d="M16.1333 0.000976562C13.9126 0.00372 11.7164 0.464697 9.68199 1.35506C7.64763 2.24543 5.81889 3.54602 4.31017 5.17549C2.80146 6.80497 1.64524 8.72825 0.913841 10.825C0.182443 12.9218 -0.108408 15.1469 0.0595097 17.3613C0.0595097 17.4142 0.0595097 17.4638 0.0595097 17.5134H0V30.8883C0 31.2392 0.13936 31.5757 0.387459 31.8238C0.635558 32.0719 0.972074 32.2113 1.32294 32.2113H15.0749C18.3989 32.4273 21.7075 31.6019 24.5405 29.8498V16.1243C24.5412 17.7933 24.0468 19.4249 23.12 20.8129C22.1932 22.2009 20.8755 23.2828 19.3337 23.9218C17.7919 24.5608 16.0952 24.7282 14.4583 24.4027C12.8214 24.0773 11.3177 23.2736 10.1376 22.0935C8.95746 20.9134 8.15383 19.4097 7.82839 17.7728C7.50295 16.1359 7.67032 14.4392 8.30931 12.8974C8.9483 11.3556 10.0302 10.0379 11.4181 9.11109C12.8061 8.18426 14.4378 7.68991 16.1068 7.69057C18.3427 7.69319 20.4864 8.58258 22.0674 10.1636C23.6485 11.7447 24.5379 13.8883 24.5405 16.1243V2.39881C22.0154 0.833787 19.1041 0.00345015 16.1333 0.000976562Z" fill="#1A51A3" />
  </svg>

);

export default Logo;
