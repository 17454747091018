/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Core Packages
import { useState, useEffect, useContext } from 'react';
import classnames from 'classnames';

// Material
import TextField from '@material-ui/core/TextField';

// Custom Components
import StyledError from '@/components/Error';
import StyledLabel from '@/components/Label';
import { PlusIcon, TrashIcon } from '@/assets/icons/shared';
import { GqlContext } from '@/contexts/gql';
import InputAdornment from '@material-ui/core/InputAdornment';
// import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
// import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

// Styles
import styles from './style.module.css';

export default function PhoneInput({
  label,
  type = 'tel',
  required = false,
  placeholder,
  className,
  value = '',
  onChange,
  altIndex,
  isFinal,
  onDelete,
  onAdd,
  canAdd,
  onError,
  disabled = false,
  id,
}) {
  const isAlt = altIndex || altIndex === 0;
  const [inputValue, setValue] = useState(value);
  const [error, setError] = useState(null);
  const containerClasses = classnames('col a-stretch', className);
  const DEFAULT_PHONE_REGEX = '^[0-9+\b]+$';
  const { country } = useContext(GqlContext);
  // const { country, countries } = useContext(GqlContext);
  const [phoneValidation, setPhoneValidation] = useState(`/^${country.phoneValidation}/gm`);
  // const [showCountries, setShowCountries] = useState(false);
  const [currentCountry, setCurrentCountry] = useState(country);
  const isSK = currentCountry?.countryCode === 'UG';
  // const defaultPlaceholder = isSK ? "712345678" : "08012345678";

  const [countryPlacholder, setCountryPlaceholder] = useState('');

  useEffect(() => setValue(value), [value]);

  const validatePhoneAlerzo = (value) => (value
    ? (value.length === 11 || value.length === 14)
        && new RegExp(phoneValidation).test(value)
    : false);

  const validatePhoneSK = (value) => (value
    ? (value.length === 10 || value.length === 13)
        && new RegExp(phoneValidation).test(value)
    : false);

  const validatePhone = isSK ? validatePhoneSK : validatePhoneAlerzo;

  const formatPhoneSK = (value) => (value[0] === '0' ? value : `0${value}`).substr(0, 10);
  const formatPhoneAlerzo = (value) => (value[0] === '0' ? value : `0${value}`).substr(0, 11);

  const formatPhone = isSK ? formatPhoneSK : formatPhoneAlerzo;

  const onValueChange = (event) => {
    if (event.target) {
      const { value } = event.target;
      if (value && !new RegExp(DEFAULT_PHONE_REGEX).test(value)) {
        return;
      }
      const formattedPhone = value;
      // const formattedPhone = value ? formatPhone(value) : value;
      setValue(formattedPhone);
      if (onChange) {
        if (isAlt) {
          onChange(formattedPhone, altIndex);
        } else {
          onChange(formattedPhone);
        }
      }
      if (onError) onError(!validatePhone(formattedPhone));

      if (error && validatePhone(formattedPhone)) {
        setError(null);
        if (onError) onError(false);
      }
    }
  };

  const onBlurHandler = () => {
    if (!validatePhone(inputValue)) {
      setError('Enter a valid Phone number.');
      if (onError) onError(true);
    }
  };

  useEffect(() => {
    setCurrentCountry(country);
    if (inputValue) {
      const formattedPhone = formatPhone(inputValue);
      if (onError) onError(!validatePhone(formattedPhone));
    }
  }, [country]);

  useEffect(() => {
    if (currentCountry?.phoneValidation) {
      setPhoneValidation(currentCountry.phoneValidation);
      setCountryPlaceholder(currentCountry.samplePhone);
    }
  }, [currentCountry]);

  return (
    <div className={containerClasses}>
      {!!label && !isAlt ? (
        <StyledLabel label={label} required={required} htmlFor="phoneInput" />
      ) : null}

      {!!label && !!isAlt ? (
        <div className={styles.altLabelContainer}>
          <StyledLabel label={label} required={required} htmlFor="phoneInput" />
          <div className={styles.iconContainer}>
            <TrashIcon
              className={styles.icon}
              onClick={() => onDelete(altIndex)}
            />
            {canAdd && isFinal && altIndex !== 4 ? (
              <PlusIcon className={styles.icon} onClick={onAdd} />
            ) : null}
          </div>
        </div>
      ) : null}

      <TextField
        className={styles.inputField}
        name="phoneInput"
        type={type}
        variant="outlined"
        required={required}
        onChange={onValueChange}
        onBlur={onBlurHandler}
        // InputProps={inputProps}
        placeholder={placeholder || countryPlacholder}
        value={inputValue}
        error={Boolean(error)}
        disabled={disabled}
        id={id}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={styles.adornment}>
              <div
                className={styles.flagContainer}
                // onClick={() => setShowCountries(!showCountries)}
              >
                <img
                  src={currentCountry?.flagUrl}
                  className={styles.flag}
                  width="20px"
                  height="15px"
                  alt="Country flag"
                />
                {/* {showCountries ? <ArrowDropUp /> : <ArrowDropDown />} */}
              </div>
              {/* {showCountries ? (
                <div className={styles.adornmentDropdown}>
                  {countries.map((ct) => (
                    <div
                      className={styles.country}
                      onClick={() => {
                        setCurrentCountry(ct);
                        setShowCountries(false);
                      }}
                    >
                      <img
                        src={ct?.flagUrl}
                        className={styles.flag}
                        width="20px"
                        height="15px"
                        alt="Country Flag"
                      />

                      {ct?.countryName}
                    </div>
                  ))}
                </div>
              ) : null} */}
            </InputAdornment>
          ),
        }}
      />
      {error && <StyledError className={styles.error} message={error} />}
    </div>
  );
}
