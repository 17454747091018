import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { AuthUserContext } from '@/contexts/authUser';

export default function useNonAuthGuard() {
  const router = useRouter();
  const { authUser, loading } = useContext(AuthUserContext);

  useEffect(() => {
    if (authUser && !loading) {
      router.replace('/');
    }
  }, [authUser, loading]);
}
