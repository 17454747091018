// Core Packages
import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useLazyQuery } from '@apollo/client';
import { GqlContext } from '@/contexts/gql';

// Material
import { LinearProgress } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Custom Components
import PageWrapper from '@/components/Layout/PageWrapper';
import SEOTags from '@/components/SEO';
import PhoneInput from '@/components/Input/PhoneInput';
import StyledButton from '@/components/Button';
import Logo from '@/assets/icons/login';
import Header from '@/components/Header';
import BackButton from '@/components/BackButton';

// Utils
import { useTranslation } from 'react-i18next';
import { GENERATE_OTP } from '@/graphql/auth';
import { NotificationContext } from '@/contexts/Notification';
import useNonAuthGuard from '@/hooks/non-auth';

// Styles
import { SKExpressLoginLogo } from '@/assets/icons/shared';
import styles from './style.module.css';

export default function Login() {
  useNonAuthGuard();
  const isDesktop = useMediaQuery('(min-width: 769px)');
  const { site } = useContext(GqlContext);
  const { t: translate } = useTranslation();
  const router = useRouter();
  const { showNotification } = useContext(NotificationContext);
  const [phone, setPhone] = useState('');
  const [sendOTP, { data, error, loading }] = useLazyQuery(GENERATE_OTP);
  const [phoneError, setPhoneError] = useState(true);

  const openVerificationPage = () => {
    document.cookie = `phone=${phone}`;
    sendOTP({ variables: { phone } });
  };

  useEffect(() => {
    if (error) {
      showNotification({
        message: error.message,
      });
    }
  }, [error]);

  useEffect(() => {
    if (!loading && data) {
      router.push({ pathname: '/login/otp', query: { phone } }, null, {
        shallow: true,
      });
    }
  }, [loading, data]);

  return (
    <PageWrapper childContainerClasses={styles.childrenBackground} childrenOnly>
      <SEOTags
        title={site?.isSK ? 'SK Express Login' : 'Alerzoshop Login'}
        description={
          site?.isSK
            ? 'Login to your SK Express App'
            : 'Login to your Alerzoshop App'
        }
        keywords={site?.isSK ? 'SK Express, Login' : 'Alerzoshop, Login'}
        siteName={site?.isSK ? 'SK Express Login' : 'Alerzoshop Login'}
      />
      <Header
        title={translate('Login')}
        showBackButton
        className={styles.smallScreen}
        id="mLoginBackbutton"
      />
      {loading && <LinearProgress />}
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          {site?.isSK ? (
            <SKExpressLoginLogo className={styles.image} />
          ) : (
            <Logo className={styles.image} />
          )}
        </div>
        <div id="dLoginBackButton" className={styles.desktopForm}>
          {isDesktop && <BackButton path="/" />}
          <h1 className={styles.heading}>
            {site?.isSK
              ? translate('Welcome to SK Express')
              : translate('Welcome to Alerzo')}
          </h1>
          <p className={styles.subheading}>
            {translate('Enter your mobile number to Register / Sign-In')}
          </p>
          <PhoneInput
            label={translate('Phone Number')}
            value={phone}
            onChange={setPhone}
            required
            onError={setPhoneError}
            id="phoneNumber"
          />
          <StyledButton
            type="button"
            className={styles.actionButton}
            onClick={openVerificationPage}
            disabled={phoneError}
            id="continueButton"
          >
            {translate('Continue')}
          </StyledButton>
        </div>
      </div>
    </PageWrapper>
  );
}
