// Core Packages
import classnames from 'classnames';
import { useRouter } from 'next/router';

// Material
import BackIcon from '@material-ui/icons/KeyboardBackspace';

// Styles
import styles from './style.module.css';

export default function BackButton({
  id, path, className, onClick,
}) {
  const router = useRouter();
  const containerClasses = classnames(styles.backButton, className);

  const handleBackClick = () => {
    if (onClick) {
      return onClick();
    }
    if (!path) {
      router.back();
    } else {
      router.replace(path);
    }
    return true;
  };

  return (
    <BackIcon id={id} className={containerClasses} onClick={handleBackClick} />
  );
}
